import { mlmApi, useChangePasswordMutation, useChangeUsernameMutation, useCreateCustomerFileMutation, useDeleteCustomerFileMutation, useLazyGetAgentInfosQuery, useRegisterAgentMutation, useRegisterNewAgentMutation, useSaveContractMutation, useSaveRibMutation } from 'apis/mlm.api';
import { stripeApi } from 'apis/stripe.api';
import { is } from 'immutable';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setAgentStatus } from 'slices/mlm.slice';
export const useMlmService = () => {
  const navigate = useNavigate();
  const [registerAgentMutation, { isLoading: isRegisterAgentMutationLoading }] = useRegisterAgentMutation();
  const [registerNewAgentMutation, { isLoading: isRegisterNewAgentMutationLoading }] = useRegisterNewAgentMutation();
  const [changePasswordMutation, { isLoading: isChangePasswordMutationLoading }] = useChangePasswordMutation();
  const [changeUsernameMutation, { isLoading: isChangeUsernameMutationLoading }] = useChangeUsernameMutation();

  const [saveContractMutation, { isLoading: isSaveContractMutationLoading }] = useSaveContractMutation();
  const [trigger] = useLazyGetAgentInfosQuery();
  const [deleteCustomerFileMutation, { isLoading: isDeleteCustomerFileLoading }] = useDeleteCustomerFileMutation();
  const [createCustomerFileMutation, { isLoading: isCreateCustomerFileLoading }] = useCreateCustomerFileMutation();
  const [saveRibMutation, { isLoading: isSaveRibMutationLoading }] = useSaveRibMutation();


  const dispatch = useDispatch();
  const registerAgent = async (datas) => {
    try {
      const result = await registerAgentMutation(datas).unwrap();
      dispatch(mlmApi.util.resetApiState());
      dispatch(stripeApi.util.resetApiState());
      // toast(`Votre inscription en tant qu'agent a été terminé avec succès.`);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const registerNewAgent = async (datas) => {
    try {
      const result = await registerNewAgentMutation(datas).unwrap();
      dispatch(mlmApi.util.resetApiState());
      dispatch(stripeApi.util.resetApiState());
      // toast(`L'inscription du nouvel agent dans votre réseau a été terminé avec succès.`);

      return result;
    } catch (error) {
      throw error;
    }
  };

  const saveContract = async (datas) => {
    try {
      const result = await saveContractMutation(datas).unwrap();
      let agentInfos = await trigger().unwrap()
      console.log("agentInfos after saving contract", agentInfos)
      await dispatch(setAgentStatus(agentInfos?.agent?.status));
      return result;
    } catch (error) {
      throw error;
    }
  };

  const saveRib = async (datas) => {
    try {
      const result = await saveRibMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };

  const changePassword = async (datas) => {
    try {
      const result = await changePasswordMutation(datas).unwrap();
      // toast(`Votre mot de passe a été modifié avec succès.`);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const changeUsername = async (datas) => {
    try {
      const result = await changeUsernameMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };

  const deleteCustomerFile = async (datas) => {
    try {
      const result = await deleteCustomerFileMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };
  const createCustomerFile = async (datas) => {
    try {
      const result = await createCustomerFileMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };
  return { changeUsername, isChangeUsernameMutationLoading,createCustomerFile, deleteCustomerFile, isCreateCustomerFileLoading, isDeleteCustomerFileLoading, saveContract, isSaveContractMutationLoading, registerAgent, isRegisterAgentMutationLoading, registerNewAgent, isRegisterNewAgentMutationLoading, changePassword, isChangePasswordMutationLoading, saveRib, isSaveRibMutationLoading };
};