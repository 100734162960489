// material-ui
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Alert, Button, Chip, Link, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFindAgentQuery } from 'apis/admin.api';
import { useGetAgentDetailsQuery, useGetCustomerFileQuery, useGetOrderQuery } from 'apis/mlm.api';
import { use } from 'react';

// ==============================|| SAMPLE PAGE ||============================== //

const AgentOrderDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data: order } = useGetOrderQuery(params.orderId);
  useEffect(() => {
    if (order == null) return;
    console.log(order);
  }, [order]);

  const orderStatus = {
    "processing": "En cours de préparation",
  }

  return (
    <MainCard title={`Détails de la commande`}>
      {order &&
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableBody>
              <TableRow key="date_created" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Date de la commande</TableCell>
                  <TableCell align="left"><b>{order?.date_created ? moment(order.date_created, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('DD-MM-YYYY-MM à HH:mm') : "-"}</b></TableCell>
                </TableRow>
                <TableRow key="total" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Total</TableCell>
                  <TableCell align="left">{order.total ?? '-'} {order.currency ?? 'EUR'}</TableCell>
                </TableRow>
                <TableRow key="status" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Statut</TableCell>
                  <TableCell align="left">{orderStatus[order.status] ?? '-'}</TableCell>
                </TableRow>

                <TableRow key="shipping_address" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Adresse de livraison</TableCell>
                  <TableCell align="left">{order.shipping.city ?? '-'}, {order.shipping.address_1 ?? '-'}, {order.shipping.address_2 ?? '-'}, {order.shipping.postcode ?? '-'}</TableCell>
                </TableRow>
                

              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <h3>Liste des articles achetés :</h3>
          {(order.line_items.length == 0) && <Alert severity="info" style={{ marginTop: 10 }}>Aucun article acheté.</Alert>}
          {(order.line_items.length != 0) && 
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell >#</TableCell>
                  <TableCell>Libellé</TableCell>
                  <TableCell>Prix unitaire</TableCell>
                  <TableCell>Quantité</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Image</TableCell>


                  <TableCell></TableCell>


                </TableRow>
              </TableHead>
              <TableBody>
                {order.line_items.map((line_item, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell >{line_item.id}</TableCell>
                    <TableCell >{line_item.name}</TableCell>
                    <TableCell >{line_item.price} {order.currency ?? 'EUR'}</TableCell>
                    <TableCell >{line_item.quantity}</TableCell>
                    <TableCell >{line_item.total} {order.currency ?? 'EUR'}</TableCell>
                    <TableCell ><img src={line_item.image.src} height={50} /></TableCell>


                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          }
          <br />
          <h3>Liste des coupons utilisés :</h3>
          {(order.coupon_lines.length == 0) && <Alert severity="info" style={{ marginTop: 10 }}>Aucun coupon utilisé.</Alert>}
          {(order.coupon_lines.length != 0) && 
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell >#</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Remise</TableCell>
                  <TableCell>Tax remisé</TableCell>
                  <TableCell>Type de la remise</TableCell>
                  <TableCell>Montant</TableCell>
                  <TableCell>Livraison gratuite</TableCell>



                  <TableCell></TableCell>


                </TableRow>
              </TableHead>
              <TableBody>
                {order.coupon_lines.map((coupon_line, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell >{coupon_line.id}</TableCell>
                    <TableCell >{coupon_line.code}</TableCell>
                    <TableCell >{coupon_line.discount}</TableCell>
                    <TableCell >{coupon_line.discount_tax}</TableCell>
                    <TableCell >{coupon_line.discount_type}</TableCell>
                    <TableCell >{coupon_line.nominal_amount} {order.currency ?? 'EUR'}</TableCell>
                    <TableCell >{coupon_line.free_shipping ? "OUI" : "NON"}</TableCell>


                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          }
        </>
      }





    </MainCard>
  );
}

export default AgentOrderDetails;
