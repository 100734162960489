
// project imports

// material-ui
import { useTheme } from '@mui/material/styles';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useEffect, useRef, useState } from 'react';
import { useGetAgentDetailsQuery } from 'apis/mlm.api';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useSelector } from 'react-redux';
import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import { useMlmService } from 'services/mlm.service';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
// ==============================|| SAMPLE PAGE ||============================== //

const AgentRibForm = () => {
  const agentStatus = useSelector((state) => state.mlm.agentStatus);
  console.log("agentStatus", agentStatus);
  const { data: agent } = useGetAgentDetailsQuery();
  const ref = useRef(null);


  useEffect(() => {
    if (agent == null) return;
    ref.current.setFieldValue("rib", agent.rib);
  }
    , [agent]);

  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(null);

  const scriptedRef = useScriptRef();
  const { saveRib, isSaveRibMutationLoading } = useMlmService();



  return (

    <>
      <h3>Votre RIB :</h3>
      <Formik
        innerRef={ref}
        initialValues={{
          rib: "",
          submit: null
        }}
        validationSchema={Yup.object().shape({
          rib: Yup.string().max(255).required("Veuillez renseigner votre rib.")

        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {


            setErrorMessage(null);
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }

            const formData = new FormData();

            formData.append("rib", values["rib"]);
            const res = await saveRib(formData);
            // toast(`RIB modifié avec succès.`);
            console.log('Result:', res);
          } catch (err) {
            console.log(err);
            setErrorMessage(err.data ? err.data.message : err.message);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', alignItems: 'flex-center', gap: '10px', maxWidth: '500px' }} >
              <FormControl fullWidth error={Boolean(touched.rib && errors.rib)} sx={{ ...theme.typography.customInput }}>
                <InputLabel htmlFor="outlined-adornment-rib-agent-registration">RIB</InputLabel>

                <OutlinedInput
                  id="outlined-adornment-rib-agent-registration"
                  type="text"
                  value={values.rib}
                  name="rib"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="RIB"
                  inputProps={{}}
                />
                {touched.rib && errors.rib && (
                  <FormHelperText error id="standard-weight-helper-text-rib-agent-registration">
                    {errors.rib}
                  </FormHelperText>
                )}
              </FormControl>
              <Box sx={{ mt: 2 }}>
                <AnimateButton>
                  <LoadingButton disableElevation loading={isSaveRibMutationLoading} fullWidth size="large" type="submit" variant="contained" color="secondary" style={{ textTransform: 'none' }}>
                    Modifier
                  </LoadingButton>
                </AnimateButton>
              </Box>
            </Box>



            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}


          </form>
        )}
      </Formik>
      {errorMessage && <Alert severity="error" style={{ marginTop: 10 }}>{errorMessage}</Alert>}
    </>
  );
}
export default AgentRibForm;
