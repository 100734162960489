import { Navigate, Route, Routes, useRoutes } from 'react-router-dom';

// routes
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';
import { useSelector } from 'react-redux';
import { useGetAgentInfosQuery } from 'apis/mlm.api';
import AdminAgentsListPage from 'views/admin/agent-page';
import AdminAgentDetailsPage from 'views/admin/agent-page/AdminAgentDetailsPage';
import AgentKitPurchase from 'views/agent/AgentKitPurchase';
import AgentContractForm from 'views/agent/AgentContractForm';
import AgentWaitingForValidation from 'views/agent/AgentWaitingForValidation';
import AgentAccountDeactivated from 'views/agent/AgentAccountDeactivatedn';
import AdminPromoCodesListPage from 'views/admin/promo-codes';
import AdminPromoCodeForm from 'views/admin/promo-codes/AdminPromoCodeForm';
import AgentDetailsPage from 'views/agent/AgentDetailsPage';
import AgentCustomerFilesListPage from 'views/customer_files';
import AgentCustomerFilesForm from 'views/customer_files/AgentCustomerFilesForm';
import AgentCustomerFilesDetails from 'views/customer_files/AgentCustomerFilesDetails';
import AgentOrdersListPage from 'views/orders';
import AgentOrderDetails from 'views/orders/AgentOrderDetails';
import AdminNetworkPage from 'views/admin/network-page';
import ChangeUsername from 'views/settings/ChangeUsername';
import AdminChangeUsernameOfAgent from 'views/admin/agent-page/AdminChangeUsernameOfAgent';

const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const EWalletPage = Loadable(lazy(() => import('views/e-wallet-page')));
const OperationsPage = Loadable(lazy(() => import('views/operations-page')));
const OperationDetailsPage = Loadable(lazy(() => import('views/operations-page/OperationDetailsPage')));

const InvoicesPage = Loadable(lazy(() => import('views/invoices-page')));
const InvoiceDetailsPage = Loadable(lazy(() => import('views/invoices-page/InvoiceDetailsPage')));
const AccountsPage = Loadable(lazy(() => import('views/accounts-page')));
const AccountDetailsPage = Loadable(lazy(() => import('views/accounts-page/AccountDetailsPage')));
const AgentRegistration = Loadable(lazy(() => import('views/agent/AgentRegistration')));
const NetworkPage = Loadable(lazy(() => import('views/network-page')));
const OnlineShop = Loadable(lazy(() => import('views/online-shop')));
const NetworkRegistration = Loadable(lazy(() => import('views/network-page/NetworkRegistration')));
const ChangePassword = Loadable(lazy(() => import('views/settings/ChangePassword')));




const AuthGuard = Loadable(lazy(() => import('routes/AuthGuard')));

// =================
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const token = useSelector((state) => state.auth.token);
  const roles = useSelector((state) => state.auth.roles);

  const agentStatus = useSelector((state) => state.mlm.agentStatus);
  console.log("agentStatus", agentStatus)
  return (
    <Routes>
      <Route path="/auth" element={<MinimalLayout />}>
        <Route path="login" element={<AuthLogin3 />} />
        <Route path="register" element={<AuthRegister3 />} />
        <Route path='' element={<Navigate to='login' />} />
      </Route>
      {token == null && <Route path='' element={<Navigate to='/auth' />} />}
      {token != null &&
        <Route element={<AuthGuard roles={roles} />}>
          <Route path="/" element={<MainLayout />}>
            {
              roles.includes("ROLE_ADMIN") &&
              <>
                <Route path="admin">
                  <Route path="agents" element={<AdminAgentsListPage />} />
                  <Route path="agents/:agentId/settings/username" element={<AdminChangeUsernameOfAgent />} />
                  <Route path="agents/:agentId" element={<AdminAgentDetailsPage />} />

                  <Route path="promo_codes" element={<AdminPromoCodesListPage />} />
                  <Route path="promo_codes/new" element={<AdminPromoCodeForm />} />
                  <Route path="network" element={<AdminNetworkPage />} />

                  <Route path='' element={<Navigate to='agents' />} />
                </Route>
                <Route path='' element={<Navigate to='admin' />} />
              </>
            }
            {
              roles.includes("ROLE_AGENT") &&
              <>
                <Route path="agent">
                  <Route path="settings/change-password" element={<ChangePassword />} />
                  <Route path="settings/change-username" element={<ChangeUsername />} />

                  {
                    // CREATED
                    (agentStatus == 0) && <>
                      <Route path="kit_purchase" element={<AgentKitPurchase />} />
                      <Route path="contract" element={<AgentContractForm />} />

                      <Route path='' element={<Navigate to='kit_purchase' />} />
                    </>
                  }
                  {
                    // WAITING FOR VALIDATION
                    (agentStatus == 2) && <>
                      <Route path="waiting_for_validation" element={<AgentWaitingForValidation />} />
                      <Route path='' element={<Navigate to='waiting_for_validation' />} />
                    </>
                  }
                  {
                    // WAITING FOR VALIDATION
                    (agentStatus == -1) && <>
                      <Route path="account_deactivated" element={<AgentAccountDeactivated />} />
                      <Route path='' element={<Navigate to='account_deactivated' />} />
                    </>
                  }
                  {
                    // ACTIVE
                    agentStatus == 1 && <>
                      <Route path="workspace">
                        <Route path="dashboard" element={<DashboardDefault />} />
                        <Route path="network" element={<NetworkPage />} />
                        <Route path="network/registration" element={<NetworkRegistration />} />
                        <Route path="shop" element={<OnlineShop />} />
                        <Route path="details" element={<AgentDetailsPage />} />
                        <Route path="customer_files" element={<AgentCustomerFilesListPage />} />
                        <Route path="customer_files/new" element={<AgentCustomerFilesForm />} />
                        <Route path="customer_files/details/:customerFileId" element={<AgentCustomerFilesDetails />} />
                        <Route path="contract" element={<AgentContractForm />} />




                        <Route path='' element={<Navigate to='dashboard' />} />
                      </Route>
                      <Route path="me">
                        <Route path="operations" element={<OperationsPage />} />
                        <Route path="invoices" element={<InvoicesPage />} />
                        <Route path="invoices/:invoiceId" element={<InvoiceDetailsPage />} />
                        <Route path="accounts" element={<AccountsPage />} />
                        <Route path="accounts/:accountId" element={<AccountDetailsPage />} />
                        <Route path="agent-registration" element={<AgentRegistration />} />
                        <Route path="orders" element={<AgentOrdersListPage />} />
                        <Route path="orders/:orderId" element={<AgentOrderDetails />} />
                        <Route path='' element={<Navigate to='accounts' />} />
                      </Route>
                      <Route path='' element={<Navigate to='workspace' />} />
                    </>
                  }

                </Route>
                <Route path='' element={<Navigate to='agent' />} />
              </>
            }



          </Route>

        </Route>
      }
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
