import { useActivateAgentMutation, useApproveSupportingDocumentMutation, useChangeUsernameOfAgentMutation, useCreatePromoCodeMutation, useDeactivateAgentMutation, useDeletePromoCodeMutation, useRejectSupportingDocumentMutation } from 'apis/admin.api';
import { stripeApi } from 'apis/stripe.api';
import { is } from 'immutable';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
export const useAdminService = () => {
  const navigate = useNavigate();
  const [activateAgentMutation, {isLoading : isActivateAgentMutationLoading}] = useActivateAgentMutation();
  const [deactivateAgentMutation, {isLoading : isDeactivateAgentMutationLoading}] = useDeactivateAgentMutation();
  const [deletePromoCodeMutation, {isLoading : isDeletePromoCodeLoading}] = useDeletePromoCodeMutation();
  const [createPromoCodeMutation, {isLoading : isCreatePromoCodeLoading}] = useCreatePromoCodeMutation();
  const [approveSupportingDocumentMutation, {isLoading : isApproveSupportingDocumentMutationLoading}] = useApproveSupportingDocumentMutation();
  const [rejectSupportingDocumentMutation, {isLoading : isRejectSupportingDocumentMutationLoading}] =  useRejectSupportingDocumentMutation();
const [changeUsernameOfAgentMutation, { isLoading: isChangeUsernameOfAgentMutationLoading }] = useChangeUsernameOfAgentMutation();




  const dispatch = useDispatch();
  const changeUsernameOfAgent = async (datas) => {
    try {
      const result = await changeUsernameOfAgentMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };

  const approveSupportingDocument = async (datas) => {
    try {
      const result = await approveSupportingDocumentMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };
  const rejectSupportingDocument = async (datas) => {
    try {
      const result = await rejectSupportingDocumentMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };
  const activateAgent = async (datas) => {
    try {
      const result = await activateAgentMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };

  const deactivateAgent = async (datas) => {
    try {
      const result = await deactivateAgentMutation(datas).unwrap();
      
      return result;
    } catch (error) {
      throw error;
    }
  };

  const deletePromoCode = async (datas) => {
    try {
      const result = await deletePromoCodeMutation(datas).unwrap();
      
      return result;
    } catch (error) {
      throw error;
    }
  };
  const createPromoCode = async (datas) => {
    try {
      const result = await createPromoCodeMutation(datas).unwrap();
      
      return result;
    } catch (error) {
      throw error;
    }
  };

  
  return { changeUsernameOfAgent, isChangeUsernameOfAgentMutationLoading,approveSupportingDocument, rejectSupportingDocument, isApproveSupportingDocumentMutationLoading, isRejectSupportingDocumentMutationLoading,activateAgent, deactivateAgent , isActivateAgentMutationLoading, isDeactivateAgentMutationLoading, deletePromoCode, isDeletePromoCodeLoading, createPromoCode, isCreatePromoCodeLoading };
};