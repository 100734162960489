// material-ui
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Chip, Link, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { useFindAgentsQuery, useFindCustomerFilesQuery } from 'apis/mlm.api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMlmService } from 'services/mlm.service';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';

// ==============================|| SAMPLE PAGE ||============================== //

const AgentCustomerFilesListPage = () => {
  const theme = useTheme();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [customerFiles, setCustomerFiles] = useState([]);
  const [count, setCount] = useState(0);
  const [nbPages, setNbPages] = useState(0);
  const { data: customer_files_datas } = useFindCustomerFilesQuery({ page: page - 1, limit: limit});
  const { deleteCustomerFile, isDeleteCustomerFileLoading} = useMlmService();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (customer_files_datas == null) return;
    console.log("customer_files_datas", customer_files_datas)
    setCustomerFiles(customer_files_datas['clients']);
    setCount(customer_files_datas['count']);
    setNbPages(customer_files_datas['nb_pages']);
  }, [customer_files_datas]);

  const handlePageChange = (e, value) => {
    setPage(value);
  }

  return (
    <MainCard title="Liste des fiches clients">
       <Button disableElevation type="submit" variant="outlined" color="secondary"  onClick={()=>navigate('/agent/workspace/customer_files/new')}>
                  Nouvelle fiche client
                </Button>
      {(!customerFiles || customerFiles.length == 0) && <Alert severity="info" style={{ marginTop: 10 }}>Aucune fiche client trouvée.</Alert>}
      { errorMessage && <Alert severity="error" style={{marginTop:10}}>{errorMessage}</Alert>}
      {customerFiles && customerFiles.length != 0 &&
        <>
          
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" >
              <TableHead>
                <TableRow>
                <TableCell>Nom d'utilisateur</TableCell>

                <TableCell>Nom(s)</TableCell>
                <TableCell>Prénom(s)</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Chiffre d'affaire</TableCell>
                <TableCell>Nombre article acheté</TableCell>
                <TableCell>Ville</TableCell>
                <TableCell>Pays</TableCell>



                  
                  <TableCell></TableCell>


                </TableRow>
              </TableHead>
              <TableBody>
                {customerFiles.map((customerFile, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell style={{ width: 25 }} ><b>{customerFile.username ?? '-'}</b></TableCell>

                    <TableCell style={{ width: 25 }} ><b>{customerFile.last_name ?? '-'}</b></TableCell>
                    <TableCell style={{ width: 25 }} ><b>{customerFile.first_name ?? '-'}</b></TableCell>
                    <TableCell style={{ width: 25 }} ><b>{customerFile.email ?? '-'}</b></TableCell>
                    <TableCell style={{ width: 200 }}><b>{`${customerFile?.total_sales ? parseFloat(customerFile?.total_sales).toFixed(2) : "-"} €`}</b></TableCell>
                    <TableCell style={{ width: 25 }} ><b>{customerFile.num_items_sold ?? '-'}</b></TableCell>
                    <TableCell style={{ width: 25 }} ><b>{customerFile.city ?? '-'}</b></TableCell>
                    <TableCell style={{ width: 25 }} ><b>{customerFile.country ?? '-'}</b></TableCell>



                     
                     {
                       <TableCell style={{ width: 75 }}>
                          <Button variant="contained" color="secondary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
                            onClick={()=>navigate('/agent/workspace/customer_files/details/' + customerFile.customer_id)}>
                            Voir
                          </Button>
                      </TableCell>
                    }
                    
                    {/*
                       <TableCell style={{ width: 100 }}>
                        <AnimateButton>
                          <LoadingButton loading={isDeleteCustomerFileLoading} variant="contained" color="primary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
                            onClick={async () => {
                              try{
                                await deleteCustomerFile(customerFile.id);
                              } catch (err) {
                                console.log(err);
                                setErrorMessage(err.data ? err.data.message : err.message);
                              }
                            }}>
                            Supprimer
                          </LoadingButton>
                        </AnimateButton>
                      </TableCell>
                      */
                    }
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination count={nbPages} color="primary" onChange={handlePageChange} />
          </div>
        </>
      }

    </MainCard>
  );
}

export default AgentCustomerFilesListPage;
