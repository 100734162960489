import MainCard from 'ui-component/cards/MainCard';
import TreeView from './TreeView';
import { useGetAdminNetworkQuery } from 'apis/admin.api';

const AdminNetworkPage = () => {
  const { data: network } = useGetAdminNetworkQuery({ rootAgentId: null });

  return (
    <MainCard title="Réseau" >
      {(network && network.length !== 0) &&
        <>
          <TreeView datas={network} />
        </>}
    </MainCard>
  );

};

export default AdminNetworkPage;
