import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Google from 'assets/images/icons/social-google.svg';
import { useAuthService } from 'services/auth.service';
import { useMlmService } from 'services/mlm.service';
import { useNavigate } from 'react-router';
import { useGetAgentDetailsQuery } from 'apis/mlm.api';

// ============================|| FIREBASE - LOGIN ||============================ //

const ChangeUsernameForm = ({ ...others }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();
  const [checked, setChecked] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const {changeUsername, isChangeUsernameMutationLoading} = useMlmService();
  const { data: agent } = useGetAgentDetailsQuery();
  const ref = useRef(null);
  useEffect(() => {
      if (agent == null) return;
      ref.current.setFieldValue("username", agent["username"]);
    }
        , [agent]);
  return (
    <>  

      <Formik
      innerRef={ref}
        initialValues={{
          username: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().max(255).required("Veuillez renseigner un nom d'utilisateur.")
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setErrorMessage(null);
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
            const datas = {
              "username": values["username"]

            }
            const res = await changeUsername(datas);

            console.log('Result:', res);
            navigate("/agent");
          } catch (err) {
            console.log(err);
            setErrorMessage(err.data ? err.data.message : err.message);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <FormControl fullWidth error={Boolean(touched.username && errors.username)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-username">Nom d'utilisateur</InputLabel>
              <OutlinedInput
                id="outlined-adornment-username"
                type="text"
                value={values.username}
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Nom d'utilisateur"
                inputProps={{}}
              />
              {touched.username && errors.username && (
                <FormHelperText error id="standard-weight-helper-text-username">
                  {errors.username}
                </FormHelperText>
              )}
            </FormControl>



            
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button disableElevation disabled={isChangeUsernameMutationLoading} fullWidth size="large" type="submit" variant="contained" color="secondary" style={{textTransform:'none'}}>
                  Changer de nom d'utilisateur
                </Button>
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
      { errorMessage && <Alert severity="error" style={{marginTop:10}}>{errorMessage}</Alert>}

    </>
  );
};

export default ChangeUsernameForm;
