
// project imports
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
// project imports
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import MainCard from 'ui-component/cards/MainCard';
import { useEffect, useRef, useState } from 'react';
import { useGetAgentInfosQuery } from 'apis/mlm.api';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useStripeService } from 'services/stripe.service';
import { useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import { useMlmService } from 'services/mlm.service';
import { LoadingButton } from '@mui/lab';
import ReactSignatureCanvas from 'react-signature-canvas';
import { borderColor, borderRadius } from '@mui/system';
import { useAdminService } from 'services/admin.service';
import { Visibility, VisibilityOff } from '@mui/icons-material';
// ==============================|| SAMPLE PAGE ||============================== //

const AgentCustomerFilesForm = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(null);
  
  const scriptedRef = useScriptRef();
  const {createCustomerFile, isCreateCustomerFileLoading} = useMlmService();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <MainCard
      sx={{
        maxWidth: 400,
        '& > *': {
          flexGrow: 1,
          flexBasis: '50%'
        }
      }}
      content={false}
    >
      <Box sx={{ p: 3 }}>

        <h1 style={{lineHeight:"35px"}}>Formulaire de création de fiche client</h1>
        <Formik
        initialValues={{
          last_name: "",
          first_name : "",
          username : "",
          email : "",
          password : "",

          submit: null
        }}
        validationSchema={Yup.object().shape({
          last_name: Yup.string().max(255).required("Veuillez renseigner le nom."),
          first_name: Yup.string().max(255).required("Veuillez renseigner le prénom."),
          username: Yup.string().max(255).required("Veuillez renseigner l'identifiant."),
          email: Yup.string().max(255).required("Veuillez renseigner l'email."),
          password: Yup.string().max(255).required('Veuillez renseigner votre mot de passe.')


        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setErrorMessage(null);
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
            const datas = {
              "last_name": values["last_name"],
              "first_name": values["first_name"],
              "username": values["username"],
              "email": values["email"],
              "password": values["password"],


            }
            console.log(datas)
            const res = await createCustomerFile(datas);

            console.log('Result:', res);
            navigate('/agent/workspace/customer_files');
          } catch (err) {
            console.log(err);
            setErrorMessage(err.data ? err.data.message : err.message);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            
            <FormControl fullWidth error={Boolean(touched.last_name && errors.last_name)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-last_name-agent-registration">Nom du client</InputLabel>
              <OutlinedInput
                id="outlined-adornment-last_name-agent-registration"
                type="text"
                value={values.last_name}
                name="last_name"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Nom du client"
                inputProps={{}}
              />
              {touched.last_name && errors.last_name && (
                <FormHelperText error id="standard-weight-helper-text-last_name-agent-registration">
                  {errors.last_name}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.first_name && errors.first_name)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-first_name-agent-registration">Prénom du client</InputLabel>
              <OutlinedInput
                id="outlined-adornment-first_name-agent-registration"
                type="text"
                value={values.first_name}
                name="first_name"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Prénom du client"
                inputProps={{}}
              />
              {touched.first_name && errors.first_name && (
                <FormHelperText error id="standard-weight-helper-text-first_name-agent-registration">
                  {errors.first_name}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.username && errors.username)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-username-agent-registration">Nom d'utilisateur du client</InputLabel>
              <OutlinedInput
                id="outlined-adornment-username-agent-registration"
                type="text"
                value={values.username}
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Nom d'utilisateur du client"
                inputProps={{}}
              />
              {touched.username && errors.username && (
                <FormHelperText error id="standard-weight-helper-text-username-agent-registration">
                  {errors.username}
                </FormHelperText>
              )}
            </FormControl>
            
            <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-email-agent-registration">Email du client</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-agent-registration"
                type="text"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Email du client"
                inputProps={{}}
              />
              {touched.email && errors.email && (
                <FormHelperText error id="standard-weight-helper-text-email-agent-registration">
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.password && errors.password)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-password-login">Mot de passe du client</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-login"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Mot de passe"
                inputProps={{}}
              />
              {touched.password && errors.password && (
                <FormHelperText error id="standard-weight-helper-text-password-login">
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>
            
            
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <LoadingButton disableElevation loading={isCreateCustomerFileLoading} fullWidth size="large" type="submit" variant="contained" color="secondary" style={{textTransform:'none'}}>
                  Créer
                </LoadingButton>
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
      { errorMessage && <Alert severity="error" style={{marginTop:10}}>{errorMessage}</Alert>}

     </Box>
    </MainCard >
  );
}
export default AgentCustomerFilesForm;
