import { adminApi } from 'apis/admin.api';
import useDimensions from 'hooks/useDimensions';
import React, { useEffect, useRef, useState } from 'react';
import Tree from 'react-d3-tree';
import { useDispatch } from 'react-redux';


export default function AdminOrgChartTree({ datas }) {
  const dispatch = useDispatch();
  const divRef = useRef(null);
  const { width, height } = useDimensions(divRef);
  const [treeDatas, setTreeDatas] = useState(null);
  console.log(datas);
  const transform = (item) => {
    return {
      "name": item.username,
      "agentId": item.id,
      "attributes": {
        "CA": `${parseFloat(item.ca).toFixed(2)} €`,
        "Position": item?.level ? item.level : "Little égérie"
      },
      "children": item.children.map((child) => transform(child)),
    }
  }

  const searchTreeByAgentId = (rootChildren, agentId) => {
    for (const node of rootChildren) {
      if (node.agentId === agentId) {
        return node; // Return the matching node
      }
      if (node.children && node.children.length > 0) {
        const result = searchTreeByAgentId(node.children, agentId); // Recurse into children
        if (result) {
          return result; // If a match is found in children, return it
        }
      }
    }
    return null; // Return null if no match is found
  }

  useEffect(() => {
    setTreeDatas({
      "name": "",
      "agentId": null,
      "attributes": {},
      "children": datas.map((child) => transform(child))
    });
  }, [datas])

  const onNodeClick = async (node, event) => {
    if (!node.data.children || node.data.children.length === 0) {
      const rootAgentId = node.data.agentId;
      try {
        const result = await dispatch(
          adminApi.endpoints.getAdminNetwork.initiate({ rootAgentId })
        ).unwrap();
        const treeDataCopy = { ...treeDatas }
        const agentTree = searchTreeByAgentId(treeDataCopy.children, rootAgentId)
        if (agentTree) agentTree.children = result.map((child) => transform(child))
        setTreeDatas(treeDataCopy)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }

  return (
    <div ref={divRef} id="treeWrapper" style={{ width: '100%', height: 'calc(100vh - 300px)' }}>
      {treeDatas && <Tree onNodeClick={onNodeClick} data={treeDatas} orientation='vertical' draggable={true} translate={{ x: width / 2, y: height / 4 }} />}
    </div>
  );
}