// material-ui
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Chip, Link, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { useFindAgentsQuery, useFindOrdersQuery } from 'apis/mlm.api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMlmService } from 'services/mlm.service';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';

// ==============================|| SAMPLE PAGE ||============================== //

const AgentOrdersListPage = () => {
  const theme = useTheme();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const [nbPages, setNbPages] = useState(0);
  const { data: orders_datas, error } = useFindOrdersQuery({ page: page - 1, limit: limit});
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const orderStatus = {
    "processing": "En cours de préparation",
  }
  useEffect(()=>{
    if (error) {
      console.log("error", error)
      setErrorMessage(error.data.message);
    }
  }, [error]);

  useEffect(() => {
    if (orders_datas == null) return;
    console.log("orders_datas", orders_datas)
    setOrders(orders_datas['orders']);
    setCount(orders_datas['count']);
    setNbPages(orders_datas['nb_pages']);
  }, [orders_datas]);

  const handlePageChange = (e, value) => {
    setPage(value);
  }

  return (
    <MainCard title="Liste des commandes">
       
      {(!orders || orders.length == 0) && <Alert severity="info" style={{ marginTop: 10 }}>Aucune commande trouvée.</Alert>}
      { errorMessage && <Alert severity="error" style={{marginTop:10}}>{errorMessage}</Alert>}
      {orders && orders.length != 0 &&
        <>
          
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" >
              <TableHead>
                <TableRow>
                <TableCell>Créée le</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Statut</TableCell>



                  
                  <TableCell></TableCell>


                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell style={{ width: 200 }}><b>{order?.date_created ? moment(order.date_created, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('DD-MM-YYYY-MM à HH:mm') : "-"}</b></TableCell>
                     
                    <TableCell style={{ width: 25 }} ><b>{order.total ?? '-'} {order.currency ?? 'EUR'}</b></TableCell>
                    <TableCell style={{ width: 25 }} ><b>{orderStatus[order.status] ?? '-'}</b></TableCell>

                     {
                       <TableCell style={{ width: 75 }}>
                          <Button variant="contained" color="secondary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
                            onClick={()=>navigate('/agent/me/orders/' + order.id)}>
                            Voir
                          </Button>
                      </TableCell>
                    }
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination count={nbPages} color="primary" onChange={handlePageChange} />
          </div>
        </>
      }

    </MainCard>
  );
}

export default AgentOrdersListPage;
