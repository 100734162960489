// material-ui
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Alert, Button, Chip, Link, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFindAgentQuery } from 'apis/admin.api';
import { useGetAgentDetailsQuery, useGetCustomerFileQuery } from 'apis/mlm.api';
import { use } from 'react';

// ==============================|| SAMPLE PAGE ||============================== //

const AgentCustomerFilesDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data: customer_file } = useGetCustomerFileQuery(params.customerFileId);
  useEffect(() => {
    if (customer_file == null) return;
    console.log(customer_file);
  }, [customer_file]);

  const customer_fileStatus = {
    "1": "Actif",
    "-1": "Inactif"
  }

  return (
    <MainCard title={`Détails de la fiche client`}>
      {customer_file &&
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableBody>
              <TableRow key="username" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Nom d'utilisateur du client</TableCell>
                  <TableCell align="left">{customer_file.username}</TableCell>
                </TableRow>
                <TableRow key="last_name" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Nom du client</TableCell>
                  <TableCell align="left">{customer_file.last_name}</TableCell>
                </TableRow>
                <TableRow key="first_name" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Prénom du client</TableCell>
                  <TableCell align="left">{customer_file.first_name}</TableCell>
                </TableRow>
                <TableRow key="email" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Email du client</TableCell>
                  <TableCell align="left">{customer_file.email}</TableCell>
                </TableRow>
                <TableRow key="total_sales" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Chiffre d'affaire</TableCell>
                  <TableCell align="left"><b>{`${customer_file?.total_sales ? parseFloat(customer_file?.total_sales).toFixed(2) : "-"} €`}</b></TableCell>
                </TableRow>
                <TableRow key="num_items_sold" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Nombre d'article acheté</TableCell>
                  <TableCell align="left">{customer_file.num_items_sold}</TableCell>
                </TableRow>
                <TableRow key="city" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Ville</TableCell>
                  <TableCell align="left">{customer_file.city}</TableCell>
                </TableRow>
                <TableRow key="country" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Pays</TableCell>
                  <TableCell align="left">{customer_file.country}</TableCell>
                </TableRow>
                

              </TableBody>
            </Table>
          </TableContainer>
        </>
      }




    </MainCard>
  );
}

export default AgentCustomerFilesDetails;
