// material-ui
import { Alert, Button, Chip, Link, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { useFindAgentsQuery } from 'apis/admin.api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';

// ==============================|| SAMPLE PAGE ||============================== //

const AdminAgentsListPage = () => {
  const theme = useTheme();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [agentStatusFilter, setAgentStatusFilter] = useState("");

  const [agents, setAgents] = useState([]);
  const [count, setCount] = useState(0);
  const [nbPages, setNbPages] = useState(0);
  const { data: agents_data } = useFindAgentsQuery({ page: page - 1, limit: limit, status : agentStatusFilter});
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (agents_data == null) return;
    console.log(agents_data)
    setAgents(agents_data['agents']);
    setCount(agents_data['count']);
    setNbPages(agents_data['nb_pages']);
  }, [agents_data]);

  const handlePageChange = (e, value) => {
    setPage(value);
  }
  const agentStatus = {
    "0": "Créé",
    "1": "Actif",
 "2": "En attente de validation",
 "-1": "Inactif",
  }
  return (
    <MainCard title="Liste des agents">
      <div>
      <Chip style={{marginRight:10}} label="Créés" variant={agentStatusFilter=="0"?"contained":"outlined"} onClick={()=>setAgentStatusFilter("0")} />
      
      <Chip style={{marginRight:10}} label="En attente de valdation" variant={agentStatusFilter=="2"?"contained":"outlined"} onClick={()=>setAgentStatusFilter("2")} />

      <Chip style={{marginRight:10}} label="Actifs" variant={agentStatusFilter=="1"?"contained":"outlined"} onClick={()=>setAgentStatusFilter("1")} />
      <Chip style={{marginRight:10}} label="Inactifs" variant={agentStatusFilter=="-1"?"contained":"outlined"} onClick={()=>setAgentStatusFilter("-1")} />
      <Chip style={{marginRight:10}} label="Tous les agents" variant={agentStatusFilter==""?"contained":"outlined"} onClick={()=>setAgentStatusFilter("")} />
      
      </div>
      {(!agents || agents.length == 0) && <Alert severity="info" style={{ marginTop: 10 }}>Aucun agent trouvé.</Alert>}
      {agents && agents.length != 0 &&
        <>
          
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" >
              <TableHead>
                <TableRow>
                <TableCell>Numéro d'inscription</TableCell>
                  <TableCell>Date de l'inscription</TableCell>
                  <TableCell>Parrain</TableCell>
                  <TableCell>Nom d'utilisateur</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell></TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {agents.map((agent, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell style={{ width: 25 }} >{agent.registrationNumber ?? '-'}</TableCell>
                    <TableCell style={{ width: 200 }}><b>{agent?.registrationDate ? moment(agent.registrationDate, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('DD-MM-YYYY-MM à HH:mm') : "-"}</b></TableCell>
                    <TableCell >{agent.sponsor ?  agent.sponsor.username: '-'}</TableCell>
                    <TableCell >{agent.username ?  agent.username: '-'}</TableCell>
                    <TableCell >{agentStatus[agent.status]}</TableCell>
                    
                    {
                       <TableCell style={{ width: 175 }}>
                        <AnimateButton>
                          <Button variant="contained" color="primary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
                            onClick={async () => {
                              navigate(`/admin/agents/${agent.id}`)
                            }}>
                            Voir les détails
                          </Button>
                        </AnimateButton>
                      </TableCell>
                    }
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination count={nbPages} color="primary" onChange={handlePageChange} />
          </div>
        </>
      }

    </MainCard>
  );
}

export default AdminAgentsListPage;
